.underline {
    position: relative;
    text-decoration: none;
    z-index: 0;
}

.underline::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 10px;
    top: 13px;
    left: 0;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
    z-index: -1;
}

.underline:hover::before {
    transform-origin: bottom left;
    transform: scaleX(1);
}

.dots {
    position: absolute;
    z-index: -1;
	background:
		linear-gradient(90deg, #fff 15px, transparent 1%) center,
		linear-gradient(0deg,  #fff 15px, transparent 1%) center,
		var(--chakra-colors-blue-50);
	background-size: 30px 30px;
	width: 210px;
	height: 210px;
    overflow: hidden;
}

.dots-dark {
    position: absolute;
    z-index: -1;
	background:
		linear-gradient(90deg, #1A202C 15px, transparent 1%) center,
		linear-gradient(0deg, #1A202C  15px, transparent 1%) center,
		var(--chakra-colors-gray-700);
	background-size: 30px 30px;
	width: 210px;
	height: 210px;
    overflow: hidden;
}

.figure {
    position: absolute;
    justify-content: right;
    top: -3rem;
    right: 5rem;
}
.figure img.image-hover {
    position: absolute;
    top: 0;
    right: -2px;
    object-fit: contain;
    opacity: 0;
    justify-content: right;
}
.figure:hover img.image-hover {
    opacity: 1;
}
.figure:hover img.image-main {
    opacity: 0;
}

.scrollUp {
    width: 10px;
}